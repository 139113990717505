<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ $t('views.appointments.title') }}
        </ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionChange="onSearch"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card
        v-for="appointment in appointments"
        :key="appointment.id"
        @click="onSelect(appointment)"
        button
      >
        <ion-card-header>
          <ion-card-subtitle>
            {{ appointment.store.name }} -
            {{ appointment.store.retailer.name }}
          </ion-card-subtitle>
          <ion-card-title v-if="appointment.intervention">
            {{ formatDate(appointment.intervention) }}
          </ion-card-title>
          <ion-card-title v-else-if="appointment.startsAt">
            {{ formatDate(appointment.startsAt) }}
          </ion-card-title>
          <ion-card-title v-else>
            {{ $t('labels.none') }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ODNBadge :color="appointment.appointmentType.color">
            {{ $t(`appointmentTypes.${appointment.appointmentType.name}`) }}
          </ODNBadge>
          <ODNBadge>
            {{
              $t(`appointmentStatuses.${appointment.appointmentStatus.name}`)
            }}
          </ODNBadge>
          <div v-if="appointment.survey">
            {{ appointment.survey.name }} -
            {{ appointment.survey.project.name }} -
            {{ appointment.survey.project.customer.name }}
          </div>
          <div v-else>
            {{ $t('labels.withoutProject') }}
          </div>
          <div v-html="appointment.notes" class="odn-mat-16"></div>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>

      <ODNNoContent v-if="appointments.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="parseMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { Filesystem, Directory } from '@capacitor/filesystem';

import { Base64 } from 'js-base64';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  toastController,
} from '@ionic/vue';

import ODNNoContent from '@c/odn-no-content.vue';
import ODNBadge from '@c/odn-badge.vue';

export default {
  name: 'OfflineAppointments',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    ODNNoContent,
    ODNBadge,
  },
  data() {
    return {
      appointments: [],
      appointmentList: [],
      page: 1,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      loading: true,
    };
  },
  computed: {
    ...mapGetters('offline', ['getSection']),
  },
  async ionViewDidEnter() {
    await this.loadLocalData();
    await this.parseData(true);
  },
  ionViewDidLeave() {
    this.appointments = [];
    this.appointmentList = [];
    this.page = 1;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    ...mapActions('appointments', ['selectAppointment']),
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    async loadLocalData() {
      this.loading = true;
      try {
        const rawData = await Filesystem.readFile({
          path: 'appointments.json',
          directory: Directory.Data,
        });

        this.appointmentList = JSON.parse(Base64.decode(rawData.data));
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.appointments.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async parseData(clear = false) {
      if (clear) {
        this.page = 0;
        this.appointments = [];
      }

      this.appointments = this.appointments.concat(
        this.appointmentList
          .filter((a) => {
            const search = this.searchText.toLowerCase();
            return (
              a.store.name.toLowerCase().indexOf(search) > -1 ||
              a.store.retailer.name.toLowerCase().indexOf(search) > -1
            );
          })
          .slice(this.page * this.limit, this.page * this.limit + this.limit)
      );

      if (this.appointments.length < this.appointmentList.length) {
        this.disableInfiniteScrolling = false;
      } else {
        this.disableInfiniteScrolling = true;
      }
    },
    async parseMoreData(event) {
      this.page++;
      await this.parseData();
      event.target.complete();
    },
    async onSearch() {
      await this.parseData(true);
    },
    async onRefresh(event) {
      await this.parseData(true);
      event.target.complete();
    },
    onSelect(appointment) {
      this.selectAppointment(appointment);
      this.$router.push(`/offline/appointments/${appointment.id}`);
    },
  },
};
</script>
